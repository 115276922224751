import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { PLACEHOLDER } from 'components/column-renderers'
import { createCohortsNamesColumn, createCohortsNamesCsvColumn } from 'features/Cohorts'
import {
  careLevels,
  educationOptions,
  highestDegreeOptions,
  racialDesignationOptions,
  yearsOfExperience,
} from 'models/user.model'
import { UserType } from 'types'
import { capitalizeFirstLetter, isDefined } from 'utils'

const personalStart: ColDef[] = [
  { headerName: 'First Name', field: 'firstname', filter: 'agTextColumnFilter' },
  { headerName: 'Last name', field: 'lastname', filter: 'agTextColumnFilter' },
  { headerName: 'Email', field: 'email', filter: 'agTextColumnFilter', minWidth: 270 },
]

const personalEnd: ColDef[] = [
  { headerName: 'Phone Number', field: 'userBasicInfo.phoneNumber', sortable: false },
  { headerName: 'Date of Birth', field: 'userBasicInfo.dateOfBirth' },
  { headerName: 'Gender', field: 'userBasicInfo.gender' },
]

const address = [
  { headerName: 'Street Address', field: 'userBasicInfo.street', sortable: false },
  { headerName: 'ZIP Code', field: 'userBasicInfo.zipCode', sortable: false },
  { headerName: 'City', field: 'userBasicInfo.city', sortable: false },
  { headerName: 'State', field: 'userBasicInfo.state', sortable: false },
  { headerName: 'Country', field: 'userBasicInfo.country', sortable: false },
]

const personOfConcern = {
  headerName: 'Person of Concern Full Name',
  field: 'personOfConcernFullName',
  minWidth: 320,
  cellRenderer: (
    props: ICellRendererParams<{
      personOfConcernFirstName: string
      personOfConcernLastName: string
    }>,
  ) => {
    const personOfConcernFirstName = props.data?.personOfConcernFirstName
    const personOfConcernLastName = props.data?.personOfConcernLastName
    if (isDefined(personOfConcernFirstName) && isDefined(personOfConcernLastName)) {
      return `${personOfConcernFirstName} ${personOfConcernLastName}`
    }
    return PLACEHOLDER
  },
}

const levelOfCare = {
  headerName: 'Level of Care',
  field: 'careLevel',
  minWidth: 320,
  cellRenderer: (props: ICellRendererParams<{ careLevel: string }>) => {
    const careLevel = props.data?.careLevel
    if (isDefined(careLevel)) {
      return careLevels.find((it) => it.value === careLevel)?.label || 'Unknown'
    }
    return PLACEHOLDER
  },
}

const totalEngagementColumn = {
  headerName: 'Total Time Online',
  field: 'totalEngagement',
  minWidth: 220,
  sortable: false,
}

const familyColumnDefs = [
  ...personalStart,
  totalEngagementColumn,
  {
    headerName: 'Promo Code',
    field: 'accessCodeCode',
    minWidth: 220,
  },
  {
    headerName: 'Promo Code Expiry Date',
    field: 'accessCodeExpiryDate',
    minWidth: 250,
  },
  {
    headerName: 'Promo Code Effective Date',
    field: 'accessCodeEffectiveDate',
    minWidth: 260,
  },
  ...address,
  personOfConcern,
  levelOfCare,
  ...personalEnd,
]

const caregiverColumnDefs = [
  ...familyColumnDefs.slice(0, 3),
  createCohortsNamesColumn('cohortNames'),
  ...familyColumnDefs.slice(3),
]

const traineeColumnDefs = [
  { headerName: 'Anonymous ID', field: 'anonymousId', minWidth: 174 },
  ...personalStart,
  createCohortsNamesColumn('cohortNames'),
  {
    headerName: 'Survey Monkey - Education',
    field: 'highestDegree',
    cellRenderer: (props: ICellRendererParams<{ [highestDegree: string]: string }>) => {
      const highestDegree = props.data?.['highestDegree']
      if (isDefined(highestDegree)) {
        return (
          highestDegreeOptions.find((it) => it.value === highestDegree)?.tableValue || 'Unknown'
        )
      }
      return PLACEHOLDER
    },
    minWidth: 260,
  },
  {
    headerName: 'Survey Monkey - Race',
    field: 'racialDesignation',
    cellRenderer: (props: ICellRendererParams<{ [racialDesignation: string]: string }>) => {
      const racialDesignation = props.data?.['racialDesignation']
      if (isDefined(racialDesignation)) {
        return (
          racialDesignationOptions.find((it) => it.value === racialDesignation)?.tableValue ||
          'Unknown'
        )
      }
      return PLACEHOLDER
    },
    minWidth: 220,
  },
  {
    headerName: 'Survey Monkey - Job title',
    field: 'jobTitle',
    cellRenderer: (props: ICellRendererParams<{ [jobTitle: string]: string }>) => {
      const jobTitle = props.data?.['jobTitle']
      return jobTitle || PLACEHOLDER
    },
    minWidth: 220,
  },
  totalEngagementColumn,
  {
    headerName: 'Institutional Access Code',
    field: 'accessCodeCode',
    minWidth: 220,
  },
  {
    headerName: 'Organization Type',
    field: 'accessCodeOrganizationType',
    minWidth: 200,
  },
  {
    headerName: 'Training Pathway',
    field: 'trainingPathwayTypeName',
    minWidth: 220,
  },
  {
    headerName: 'Years of Experience',
    field: 'experience',
    cellRenderer: (props: ICellRendererParams<{ [experience: string]: string }>) => {
      const experience = props.data?.['experience']
      if (isDefined(experience)) {
        return yearsOfExperience.find((it) => it.value === experience)?.label || 'Unknown'
      }
      return PLACEHOLDER
    },
  },
  {
    headerName: 'Education',
    field: 'education',
    filter: 'agTextColumnFilter',
    cellRenderer: (props: ICellRendererParams<{ education: string }>) => {
      const education = props.data?.education
      if (isDefined(education)) {
        return educationOptions.find((it) => it.value === education)?.label || 'Unknown'
      }
      return PLACEHOLDER
    },
  },
  {
    headerName: 'Language Preference',
    field: 'language',
    cellRenderer: (props: ICellRendererParams<{ [language: string]: string }>) => {
      const language = props.data?.['language']
      return language ? capitalizeFirstLetter(language) : PLACEHOLDER
    },
    minWidth: 230,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Access Code Expiry Date',
    field: 'accessCodeExpiryDate',
    minWidth: 250,
  },
  {
    headerName: 'Access Code Effective Date',
    field: 'accessCodeEffectiveDate',
    minWidth: 260,
  },
  ...address,
  ...personalEnd,
]

export const csvTraineeFields = [
  { label: 'Anonymous ID', value: 'anonymousId' },
  { label: 'First Name', value: 'firstname' },
  { label: 'Last name', value: 'lastname' },
  { label: 'Email', value: 'email' },
  createCohortsNamesCsvColumn('cohortNames'),
  {
    label: 'Survey Monkey - Education',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: ({ highestDegree }: any) => {
      if (isDefined(highestDegree)) {
        return (
          highestDegreeOptions.find((it) => it.value === highestDegree)?.tableValue || 'Unknown'
        )
      }
      return PLACEHOLDER
    },
  },
  {
    label: 'Survey Monkey - Race',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: ({ racialDesignation }: any) => {
      if (isDefined(racialDesignation)) {
        return (
          racialDesignationOptions.find((it) => it.value === racialDesignation)?.tableValue ||
          'Unknown'
        )
      }
      return PLACEHOLDER
    },
  },
  { label: 'Survey Monkey - Job title', value: 'jobTitle' },
  { label: 'Total Time Online', value: 'totalEngagement' },
  { label: 'Institutional Access Code', value: 'accessCodeCode' },
  { label: 'Organization Type', value: 'accessCodeOrganizationType' },
  { label: 'Training Pathway', value: 'trainingPathwayTypeName' },
  {
    label: 'Years of Experience',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: ({ experience }: any) => {
      if (isDefined(experience)) {
        return yearsOfExperience.find((it) => it.value === experience)?.label || 'Unknown'
      }
      return PLACEHOLDER
    },
  },
  {
    label: 'Education',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: ({ education }: any) => {
      if (isDefined(education)) {
        return educationOptions.find((it) => it.value === education)?.label || 'Unknown'
      }
      return PLACEHOLDER
    },
  },
  {
    label: 'Language Preference',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: ({ language }: any) => {
      return language ? capitalizeFirstLetter(language) : PLACEHOLDER
    },
  },
  { label: 'Access Code Expiry Date', value: 'accessCodeExpiryDate' },
  { label: 'Access Code Effective Date', value: 'accessCodeEffectiveDate' },
  { label: 'Street Address', value: 'userBasicInfo.street' },
  { label: 'ZIP Code', value: 'userBasicInfo.zipCode' },
  { label: 'City', value: 'userBasicInfo.city' },
  { label: 'State', value: 'userBasicInfo.state' },
  { label: 'Country', value: 'userBasicInfo.country' },
  { label: 'Phone Number', value: 'userBasicInfo.phoneNumber' },
  { label: 'Date of Birth', value: 'userBasicInfo.dateOfBirth' },
  { label: 'Gender', value: 'userBasicInfo.gender' },
]

const adminsColumnDefs = [
  ...personalStart,
  { headerName: 'Phone Number', field: 'phoneNumber', sortable: false },
]

export const getUserColumnDefs = (userType: string) => {
  switch (userType) {
    case UserType.TRAINEE:
      return traineeColumnDefs
    case UserType.CAREGIVER:
      return caregiverColumnDefs
    case UserType.CARETEAM:
      return familyColumnDefs
    case UserType.ADMIN:
      return adminsColumnDefs
  }
  return personalStart
}
