import { Box } from '@mui/material'
import { deleteQuery } from 'api/query'
import { deleteQuestionnaire } from 'api/questionaries'
import { deleteQuiz } from 'api/quiz'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import { UIEntityItemExam } from 'features/UI'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import { ExamTitleMap } from 'models/exam.model'
import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { QuestioningType } from 'types'

const OptionsByType = {
  quiz: {
    routeEdit: routes.quizEdit,
    deleteRequest: deleteQuiz,
  },
  questionnaire: {
    routeEdit: routes.questionnaireEdit,
    deleteRequest: deleteQuestionnaire,
  },
  query: {
    routeEdit: routes.queryEdit,
    deleteRequest: deleteQuery,
  },
}

export function ExamPreviewPanel({
  type,
  addControl = null,
  id,
  name = '',
  onReload,
}: {
  type: QuestioningType
  addControl?: ReactNode
  id?: number | null
  name?: string
  onReload: () => void
}) {
  const navigate = useNavigate()

  const { routeEdit, deleteRequest } = OptionsByType[type]
  const entityName = ExamTitleMap[type]

  const removeModal = useRemoveModalControl({
    deleteFunc: deleteRequest,
    successCallback: onReload,
    warning: `Can't remove ${entityName} from course`,
  })

  const handleEdit = () => {
    navigate(`${routeEdit}/?id=${id}&type=${type}`)
  }

  const handleDelete = () => {
    if (id === undefined || id === null) return
    removeModal.openDeleteModal(id)
  }

  return (
    <>
      {!id && addControl && <Box>{addControl}</Box>}

      {id && (
        <>
          <UIEntityItemExam
            quizId={id}
            onEdit={handleEdit}
            onDelete={handleDelete}
            type={type}
            name={name}
          />

          <ConfirmRemoveModal
            entityToRemove={entityName}
            loading={removeModal.removeLoading}
            isOpen={removeModal.idToRemove !== null}
            handleConfirm={removeModal.handleConfirmRemove}
            handleClose={removeModal.closeDeleteModal}
          />
        </>
      )}
    </>
  )
}
