import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import LoadingButton from '@mui/lab/LoadingButton'
import { Button, FormLabel, Stack } from '@mui/material'
import { createMediaFile } from 'api/mediaFiles'
import Breadcrumbs from 'components/Breadcumbs'
import ModalWithTextInput from 'components/modals/simple-modals/ModalWithTextInput'
import UploadAudioModal from 'components/modals/UploadAudioModal'
import NotificationSys from 'components/NotificationSystem'
import PageTitle from 'components/page/PageTitle'
import { FIELD_IS_REQUIRED } from 'const'
import { useQuestionAudios } from 'features/Questions/hooks/useQuestionAudios'
import { QuestionUploadAudioFiles } from 'features/Questions/models/QuestionUploadAudioFile'
import { useShowControl } from 'hooks/useShowControl'
import { useUploadAudioModal } from 'hooks/useUploadAudioModal'
import capitalize from 'lodash/capitalize'
import uniqBy from 'lodash/uniqBy'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { routes } from 'routes/routes'
import { EQuestionType, Nullish, Question, QuestionAnswer } from 'types'
import { getRoute } from 'utils'
import { object, string, TypeOf } from 'zod'
import { QuestionEditorFieldAnswersList } from '../QuestionEditorFieldAnswersList/QuestionEditorFieldAnswersList'
import { QuestionEditorFieldExplanation } from '../QuestionEditorFieldExplanation/QuestionEditorFieldExplanation'
import { QuestionEditorFieldInstruction } from '../QuestionEditorFieldInstruction/QuestionEditorFieldInstruction'
import { QuestionEditorFieldQuestion } from '../QuestionEditorFieldQuestion/QuestionEditorFieldQuestion'
import { QuestionEditorFieldStem } from '../QuestionEditorFieldStem/QuestionEditorFieldStem'

const MAX_LENGTH_ERROR_MESSAGE = (max_length: number) =>
  `Please enter a maximum of ${max_length} characters`

const MAX_LENGTH = {
  stem: 500,
  question: 250,
  instruction: 50,
  explanation: 700,
  option: 300,
}

const CORRECT_ANSWER_IS_REQUIRED = 'Options and correct answer are required'

const formSchema = (type: EQuestionType) =>
  object({
    stem:
      type !== EQuestionType.query
        ? string()
            .min(1, FIELD_IS_REQUIRED)
            .max(MAX_LENGTH.stem, MAX_LENGTH_ERROR_MESSAGE(MAX_LENGTH.stem))
        : string().optional(),
    question: string()
      .min(1, FIELD_IS_REQUIRED)
      .max(MAX_LENGTH.question, MAX_LENGTH_ERROR_MESSAGE(MAX_LENGTH.question)),
    instructions: string()
      .min(1, FIELD_IS_REQUIRED)
      .max(MAX_LENGTH.instruction, MAX_LENGTH_ERROR_MESSAGE(MAX_LENGTH.instruction)),
    explanation:
      type !== EQuestionType.query
        ? string()
            .min(1, FIELD_IS_REQUIRED)
            .max(MAX_LENGTH.explanation, MAX_LENGTH_ERROR_MESSAGE(MAX_LENGTH.explanation))
        : string(),
    correctAnswer:
      type !== EQuestionType.query
        ? string({ required_error: CORRECT_ANSWER_IS_REQUIRED }).min(1, CORRECT_ANSWER_IS_REQUIRED)
        : string().optional(),
  })

const Labels = {
  [EQuestionType.question]: {
    title: 'Quiz / Exam Question View',
    routeName: 'Quiz / Exam Questions',
    entity: 'quiz',
  },
  [EQuestionType.query]: {
    title: 'Query Question View',
    routeName: 'Query Questions',
    entity: 'query',
  },
}

export const QuestionEditor = ({
  editorType,
  data,
  onSubmitChangeName,
  onSaveQuestion,
  saving,
}: {
  editorType: 'add' | 'edit'
  data: Question
  onSubmitChangeName: (newName: string) => void
  onSaveQuestion: (question: Question) => void
  saving: boolean
}) => {
  const {
    name,
    stem,
    question,
    type,
    explanation,
    instructions,
    answers: initialAnswers,
    correctAnswer: initialCorrectAnswer,
  } = data

  const initialCorrectId = initialAnswers.findIndex((it) => it.text === initialCorrectAnswer)

  const [answers, setAnswers] = useState(!initialAnswers?.length ? [] : initialAnswers)
  const [correctId, setCorrectId] = useState<number>(initialCorrectId)
  const [isOpenEditQuestionName, openEditQuestionName, closeEditQuestionName] = useShowControl()

  const pageTitle = useMemo(() => {
    return `${capitalize(editorType)} ${Labels[type].title}`
  }, [editorType, type])

  const handleSubmitChangeName = useCallback(
    (name: string) => {
      onSubmitChangeName(name)
      closeEditQuestionName()
    },
    [closeEditQuestionName, onSubmitChangeName],
  )

  const formSettings = useMemo(() => {
    return {
      mode: 'onChange' as const,
      resolver: zodResolver(formSchema(type)),
      defaultValues: {
        stem,
        question,
        instructions,
        explanation,
        correctAnswer: initialCorrectAnswer,
      },
    }
  }, [type, stem, question, instructions, explanation, initialCorrectAnswer])

  type FormType = TypeOf<ReturnType<typeof formSchema>>

  const methods = useForm<FormType>(formSettings)
  const { handleSubmit, setValue, getFieldState } = methods

  const { uploadFiles, setUploadFiles } = useQuestionAudios(data)
  const onSubmitHandler: SubmitHandler<FormType> = async (values: FormType) => {
    if (answers.some((it) => it.text.trim() === '')) {
      return
    }

    if (answers.some((it) => it.text.length > MAX_LENGTH.option)) {
      return
    }

    const uniqAnswers = uniqBy(answers, 'text')
    const isUnique = uniqAnswers.length === answers.length
    if (!isUnique) {
      NotificationSys.showWarning('Some of answers are not unique. Please correct before saving')
      return
    }

    const {
      enQuestionMediaFile,
      enExplanationMediaFile,
      esQuestionMediaFile,
      esExplanationMediaFile,
    } = uploadFiles

    const isMissedQuestion =
      (!enQuestionMediaFile && enExplanationMediaFile) ||
      (!esQuestionMediaFile && esExplanationMediaFile)
    const isMissedExplanation =
      (enQuestionMediaFile && !enExplanationMediaFile) ||
      (esQuestionMediaFile && !esExplanationMediaFile)
    if (isMissedQuestion || isMissedExplanation) {
      const audioFilesValidationMessage = `Can't save the ${Labels[type].entity}. Please add ${
        isMissedQuestion ? 'question' : 'explanation'
      } audio`
      NotificationSys.showWarning(audioFilesValidationMessage)
      return
    }

    const newEntity: Question = {
      id: data.id,
      name,
      type: data.type,
      stem: values.stem as string,
      question: values.question,
      instructions: values.instructions,
      explanation: values.explanation,
      correctAnswer: answers[correctId]?.text,
      answers,
      enQuestionMediaFileId: enQuestionMediaFile?.id || null,
      enExplanationMediaFileId: enExplanationMediaFile?.id || null,
      esQuestionMediaFileId: esQuestionMediaFile?.id || null,
      esExplanationMediaFileId: esExplanationMediaFile?.id || null,
    }

    onSaveQuestion(newEntity)
  }

  const onChangeAnswers = (answers: QuestionAnswer[]) => {
    setAnswers(answers)
    if (correctId !== null && correctId >= answers.length) {
      setCorrectId(answers.length - 1)
      setValue('correctAnswer', answers[answers.length - 1].text)
    } else if (correctId !== null) {
      setValue('correctAnswer', answers[correctId].text)
    }
  }

  const path = [
    {
      href: getRoute(routes.questions, { type }),
      text: Labels[type].routeName,
    },
    {
      text: name,
    },
  ]

  const {
    mediaLoading,
    setMediaLoading,
    isOpen,
    handleOpen,
    handleClose,
    progressBarValue,
    onUploadProgress,
  } = useUploadAudioModal()

  const fileAbortControllerRef = useRef(new AbortController())
  const onAbortUpload = () => {
    fileAbortControllerRef.current.abort()
    fileAbortControllerRef.current = new AbortController()
  }

  const onSubmitAudio = async (newFile: File) => {
    let fileId: Nullish<number> = null
    try {
      const mediaResponse = await createMediaFile(
        newFile,
        onUploadProgress,
        fileAbortControllerRef.current.signal,
      )
      fileId = mediaResponse.data.id
    } catch (e) {
      throw e
    }
    return fileId
  }

  const onChangeAudio = async (uploadFiles: QuestionUploadAudioFiles) => {
    setMediaLoading(true)
    try {
      const {
        enQuestionMediaFile,
        enExplanationMediaFile,
        esQuestionMediaFile,
        esExplanationMediaFile,
      } = uploadFiles

      if (enQuestionMediaFile && !enQuestionMediaFile.id && enQuestionMediaFile.file) {
        enQuestionMediaFile.id = await onSubmitAudio(enQuestionMediaFile.file)
      }
      if (enExplanationMediaFile && !enExplanationMediaFile.id && enExplanationMediaFile.file) {
        enExplanationMediaFile.id = await onSubmitAudio(enExplanationMediaFile.file)
      }
      if (esQuestionMediaFile && !esQuestionMediaFile.id && esQuestionMediaFile.file) {
        esQuestionMediaFile.id = await onSubmitAudio(esQuestionMediaFile.file)
      }
      if (esExplanationMediaFile && !esExplanationMediaFile.id && esExplanationMediaFile.file) {
        esExplanationMediaFile.id = await onSubmitAudio(esExplanationMediaFile.file)
      }
      setUploadFiles(uploadFiles)
    } catch (error) {
      return
    } finally {
      setMediaLoading(false)
    }
    handleClose()
    NotificationSys.showSuccess('Audio files are changed successfully')
  }

  const onChangeCorrectId = (correctId: number, correctAnswer: string) => {
    setCorrectId(correctId)
    setValue('correctAnswer', correctAnswer)
  }

  const audioButtonLabel =
    uploadFiles.enQuestionMediaFile ||
    uploadFiles.enExplanationMediaFile ||
    uploadFiles.esQuestionMediaFile ||
    uploadFiles.esExplanationMediaFile
      ? 'Edit Audio'
      : 'Add Audio'

  return (
    <>
      <FormProvider {...methods}>
        <Stack
          component="form"
          onSubmit={handleSubmit(onSubmitHandler)}
          width="100%"
          noValidate
          spacing={3}
        >
          <Stack spacing={2} mb={-1}>
            <PageTitle>{pageTitle}</PageTitle>

            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={2} alignItems="center">
                <Breadcrumbs path={path} />
                <Button variant="outlined" onClick={openEditQuestionName}>
                  Change Name
                </Button>
                <Button variant="outlined" onClick={handleOpen} sx={{ minWidth: 90 }}>
                  {audioButtonLabel}
                </Button>
              </Stack>
              <LoadingButton
                variant="contained"
                type="submit"
                sx={{ minWidth: 80 }}
                loading={saving || mediaLoading}
              >
                Save
              </LoadingButton>
            </Stack>
          </Stack>
          <QuestionEditorFieldStem value={stem} />
          <Stack spacing={1}>
            <FormLabel>Question</FormLabel>
            <QuestionEditorFieldQuestion value={question} maxLength={MAX_LENGTH.question} />
          </Stack>
          <QuestionEditorFieldInstruction value={instructions} maxLength={MAX_LENGTH.instruction} />
          <QuestionEditorFieldAnswersList
            answers={answers}
            correctId={correctId}
            onChangeAnswers={onChangeAnswers}
            onChangeCorrectId={onChangeCorrectId}
            maxLength={MAX_LENGTH.option}
            type={type}
            error={getFieldState('correctAnswer').error}
          />
          {type !== EQuestionType.query && (
            <QuestionEditorFieldExplanation
              maxLength={MAX_LENGTH.explanation}
              value={explanation}
            />
          )}
        </Stack>
      </FormProvider>

      <ModalWithTextInput
        value={name}
        title="Change Question name"
        label="Question name"
        errorText="Question name is required"
        buttonText="Save"
        isOpen={isOpenEditQuestionName}
        handleClose={closeEditQuestionName}
        onSubmit={handleSubmitChangeName}
      />

      <UploadAudioModal
        isOpen={isOpen}
        onSubmit={onChangeAudio}
        handleClose={handleClose}
        onAbortUpload={onAbortUpload}
        title="Upload Audio"
        loading={mediaLoading}
        progressBarValue={progressBarValue}
        initialFiles={uploadFiles}
      />
    </>
  )
}
