import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, Stack, Typography } from '@mui/material'
import Breadcrumbs, { Path } from 'components/Breadcumbs'
import FormInput from 'components/form-elements/FormInput'
import { Option } from 'components/form-elements/FormSelect'
import { AddQuestionModal } from 'components/modals/lms/AddQuestionToQuizModal'
import PageTitle from 'components/page/PageTitle'
import { useCharactersLeft } from 'hooks/useCharactersLeft'
import { ExamTitleMap } from 'models/exam.model'
import React, { ChangeEvent } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { FormProvider, useForm } from 'react-hook-form'
import { Question, QuestioningType } from 'types'
import { getDragDropListStyle } from 'utils'
import { object, string, TypeOf } from 'zod'
import { ExamQuestionDraggableItem } from '../ExamQuestionDraggableItem/ExamQuestionDraggableItem'

const formSchema = object({
  introduction: string()
    .min(1, 'Introduction is required')
    .max(500, 'Please enter a maximum of 500 characters'),
})

type FormType = TypeOf<typeof formSchema>

export function ExamPageEditForm({
  id,
  type,
  path,
  intro,
  questions,
  isOpenAddQuestion,
  onClickSave,
  onChangeIntro,
  openAddQuestion,
  onDragEnd,
  removeQuestion,
  closeAddQuestion,
  onAddQuestion,
  onEditName,
}: {
  id: string
  type: QuestioningType
  path: Path[]
  onClickSave: () => void
  intro: string
  onChangeIntro: (event: ChangeEvent<HTMLInputElement>) => void
  openAddQuestion: () => void
  questions: Question[]
  onDragEnd: (result: DropResult) => void
  removeQuestion: (idToDelete: number) => void
  isOpenAddQuestion: boolean
  closeAddQuestion: () => void
  onAddQuestion: (question: Option) => void
  onEditName: () => void
}) {
  const pageTitle = `${ExamTitleMap[type]} View`

  const formSettings = {
    mode: 'onChange' as const,
    resolver: zodResolver(formSchema),
    defaultValues: {
      introduction: intro,
    },
  }

  const methods = useForm<FormType>(formSettings)

  const { handleSubmit } = methods

  const charactersLeft = useCharactersLeft(500, intro.length)

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onClickSave)} width="100%" noValidate>
        <PageTitle mb={2}>{pageTitle}</PageTitle>

        <Stack spacing={2}>
          <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
            <Stack flexDirection="row" alignItems="center">
              <Breadcrumbs path={path} />
              <Button variant="outlined" onClick={onEditName} sx={{ marginLeft: 2 }}>
                {type === 'questionnaire' ? 'Change Name and Icon' : 'Change Name'}
              </Button>
            </Stack>
            <Button variant="contained" type="submit">
              Save
            </Button>
          </Stack>

          <Stack spacing={0.5}>
            <Typography>{`${ExamTitleMap[type]} Introduction`}</Typography>
            <FormInput
              name="introduction"
              label="Introduction"
              placeholder="Start typing"
              fullWidth
              minRows={3}
              maxRows={6}
              multiline
              onChange={onChangeIntro}
              helperText={charactersLeft}
            />
          </Stack>

          {/* --- Questions --- */}
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
            <Typography variant="h6" fontWeight={400}>
              Questions
            </Typography>
            <Button variant="contained" onClick={openAddQuestion}>
              Add Question
            </Button>
          </Stack>

          {questions.length === 0 && (
            <Typography color="textSecondary">
              No questions were added yet. Please add questions.
            </Typography>
          )}

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getDragDropListStyle(snapshot.isDraggingOver)}
                >
                  {questions.map((item, index) => (
                    <ExamQuestionDraggableItem
                      key={item.id}
                      id={item.id}
                      onDelete={removeQuestion}
                      title={item.name}
                      index={index}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <AddQuestionModal
            entityId={id}
            type={type}
            isOpen={isOpenAddQuestion}
            handleClose={closeAddQuestion}
            onAdd={onAddQuestion}
          />
        </Stack>
      </Box>
    </FormProvider>
  )
}
