import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Collapse, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { UIEntityItemBox } from 'features/UI'
import React, { CSSProperties, useState } from 'react'
import { Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd'
import { Question } from 'types'

const getDraggableItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
): CSSProperties => ({
  userSelect: 'none',
  margin: `0 0 16px 0`,
  background: isDragging ? grey[300] : '#EDEFF0',
  ...draggableStyle,
})

export function QueryQuestion({
  question,
  index,
  onDelete,
}: {
  question: Question
  index: number
  onDelete: (id: number) => void
}) {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleDelete = () => {
    onDelete(question.id)
  }

  return (
    <Draggable draggableId={`item-${question.id}`} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getDraggableItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <UIEntityItemBox
            isDashed
            dragHandleProps={provided.dragHandleProps}
            onDelete={handleDelete}
            onClick={handleToggle}
            additionalElements={
              <Collapse in={isOpen}>
                <Typography variant="body1">
                  <b>Question:</b> {question.question}
                </Typography>
                <b>Answers:</b>
                {question.answers.map((answer, index) => (
                  <Typography key={index} variant="body1">
                    {index + 1}. {answer.text} ({answer.points}{' '}
                    {answer.points && answer.points > 1 ? 'points' : 'point'})
                  </Typography>
                ))}
              </Collapse>
            }
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body1" fontSize="1.125rem">
                {question.name}
              </Typography>
              {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Stack>
          </UIEntityItemBox>
        </div>
      )}
    </Draggable>
  )
}
