import { Button, Stack, Typography } from '@mui/material'
import { saveQueryRangeOrder } from 'api/query'
import NotificationSys from 'components/NotificationSystem'
import { useShowControl } from 'hooks/useShowControl'
import isEqual from 'lodash/isEqual'
import React, { useCallback, useMemo, useState } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { QueryRange } from 'types'
import { getDragDropListStyle, reorder } from 'utils'
import { QueryRangeItem } from '../QueryRangeItem/QueryRangeItem'
import { QueryRangeModalForm } from '../QueryRangeModalForm/QueryRangeModalForm'

export function QueryRanges({
  queryId,
  maxPoints,
  ranges,
  onReloadQuery,
  onDragEnd,
}: {
  maxPoints: number
  queryId: number
  ranges: QueryRange[]
  onReloadQuery: () => Promise<void>
  onDragEnd: (ranges: QueryRange[]) => void
}) {
  const [isOpenRangeForm, onOpenRangeForm, onCloseRangeForm] = useShowControl()

  const [rangeForEdit, setRangeForEdit] = useState<QueryRange | null>(null)

  const handleEdit = useCallback(
    (range: QueryRange) => {
      setRangeForEdit(range)
      onOpenRangeForm()
    },
    [onOpenRangeForm],
  )

  const handleClose = useCallback(() => {
    setRangeForEdit(null)
    onCloseRangeForm()
  }, [onCloseRangeForm])

  const handleDragEnd = useCallback(
    async (result: DropResult) => {
      try {
        if (!result.destination) return

        const reorderedRanges = reorder(ranges, result.source.index, result.destination.index)

        const originalOrder = ranges.map((range) => range.id)
        const order = reorderedRanges.map((range) => range.id)

        onDragEnd(reorderedRanges)

        if (isEqual(originalOrder, order)) return

        await saveQueryRangeOrder({
          id: queryId,
          resultRanges: order,
        })

        NotificationSys.showSuccess('The ranges order was saved')
      } catch (e) {
        NotificationSys.showWarning('An error occurred while saving the ranges order')
      }
    },
    [onDragEnd, queryId, ranges],
  )

  const hasGaps = useMemo(() => {
    // check gaps in ranges
    const rangesWithIndex = ranges.map((range, index) => ({
      ...range,
      originalIndex: index,
    }))

    rangesWithIndex.sort((a, b) => a.pointsFrom - b.pointsFrom)

    let isGaps = false
    for (let i = 0; i < rangesWithIndex.length - 1; i++) {
      if (rangesWithIndex[i].pointsTo + 1 < rangesWithIndex[i + 1].pointsFrom) {
        isGaps = true
        break
      }
    }

    return isGaps
  }, [ranges])

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
        <Typography variant="h6" fontWeight={400}>
          Result Ranges
        </Typography>
        <Button variant="contained" onClick={onOpenRangeForm}>
          Add Result Range
        </Button>
      </Stack>

      {ranges.length === 0 && <Typography>No result ranges</Typography>}

      {hasGaps && (
        <div>
          <Typography color="red">There is a gap in range values. Please check</Typography>
        </div>
      )}

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppableRanges">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getDragDropListStyle(snapshot.isDraggingOver)}
            >
              {ranges.map((range, index) => {
                return (
                  <QueryRangeItem
                    key={range.id}
                    range={range}
                    index={index}
                    onEdit={handleEdit}
                    onReloadQuery={onReloadQuery}
                  />
                )
              })}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <QueryRangeModalForm
        range={rangeForEdit}
        ranges={ranges}
        isOpen={isOpenRangeForm}
        maxPoints={maxPoints}
        queryId={queryId}
        onClose={handleClose}
        onReloadQuery={onReloadQuery}
      />
    </Stack>
  )
}
