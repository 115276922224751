import FormInput from 'components/form-elements/FormInput'
import { useCharactersLeft } from 'hooks/useCharactersLeft'
import { ChangeEvent, useCallback, useState } from 'react'

export function QuestionEditorFieldStem({ value }: { value: string }) {
  const [stem, setStem] = useState(value)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setStem(e.target.value)
    },
    [setStem],
  )

  const charactersLeft = useCharactersLeft(500, stem?.length)

  return (
    <FormInput
      name="stem"
      label="Stem"
      placeholder="Start typing"
      fullWidth
      minRows={3}
      maxRows={6}
      multiline
      onChange={handleChange}
      helperText={charactersLeft}
      checkTouchField={false}
    />
  )
}
