import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { PropsWithChildren, SyntheticEvent, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

type CustomIframeProps = {
  width: string
  height: string
}

const CustomIframe = ({
  children,
  width = '380px',
  height = 'inherit',
}: PropsWithChildren<CustomIframeProps>) => {
  const [contentRef, setContentRef] = useState<HTMLIFrameElement>()
  const [mountNode, setMountNode] = useState(contentRef?.contentWindow?.document?.body)

  useEffect(() => {
    const head = contentRef?.contentWindow?.document?.head
    const css = `
      * {
        box-sizing: border-box;
      }
      body { 
        margin: 0;
        overscroll-behavior-x: none;
        font-size: 20px;
      }
    `
    const style = contentRef?.contentWindow?.document?.createElement('style')
    if (!style) return
    style.appendChild(document.createTextNode(css))
    head?.appendChild(style)
  }, [contentRef?.contentWindow?.document])

  const cache = createCache({
    key: 'css',
    container: contentRef?.contentWindow?.document?.head,
    prepend: true,
  })

  // https://github.com/facebook/react/issues/22847
  // Firefox clear iframe body after onload event
  // Meanwhile chrome doesn't support onLoad event on iframe at all
  // So we need to handle both cases
  const handleLoad = (event: SyntheticEvent<HTMLIFrameElement>) => {
    const iframe = event.target as HTMLIFrameElement
    if (iframe?.contentDocument) {
      setMountNode(iframe.contentDocument.body)
    }
  }

  const iframeBody = contentRef?.contentWindow?.document?.body || mountNode

  return (
    <CacheProvider value={cache}>
      <iframe
        ref={setContentRef as (value: HTMLIFrameElement) => void}
        onLoad={handleLoad}
        style={{
          display: 'block',
          height,
          border: 'none',
          overflow: 'hidden',
          width,
        }}
      >
        {iframeBody && createPortal(children, iframeBody)}
      </iframe>
    </CacheProvider>
  )
}

export default CustomIframe
