import styled from '@emotion/styled'
import { Stack } from '@mui/material'

export const RangeContainer = styled(Stack)`
  border: 1px solid rgba(0, 0, 0, 0.23);
  background: #fff;
  padding: 16px;
  flex-direction: row;
  gap: 16px;
  margin-top: 16px;
`

export const PointsContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  align-items: flex-start;
  width: 600px;
`
