import { getQueryById } from 'api/query'
import FailPlaceholder from 'components/placeholders/FailPlaceholder'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import { useEntityDataControl } from 'hooks/useEntityDataControl'
import { useSearchParamsObject } from 'utils'
import { QueryPageEditForm } from '../QueryPageEditForm/QueryPageEditForm'

export function QueryPageEdit() {
  const searchParams = useSearchParamsObject()
  const id = searchParams.id as string

  const { data, loading, setLoading, setData, reloadData } = useEntityDataControl({
    id,
    loadFunc: getQueryById,
    warning: "Can't load data",
  })

  if (loading) {
    return <LoadingPlaceholder />
  }

  if (!data) {
    return <FailPlaceholder error="Can't load data of Query" />
  }

  return (
    <QueryPageEditForm
      data={data}
      loading={loading}
      setData={setData}
      reloadData={reloadData}
      setLoading={setLoading}
    />
  )
}
