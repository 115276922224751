import { getLibraryArticlesByName } from 'api/learnArticles'
import LinkToLearnLibraryModal from 'components/modals/lms/LinkToLearnLibraryModal'
import { UIEntityItemConnection } from 'features/UI'
import { useShowControl } from 'hooks/useShowControl'
import { pathwayCacheTime } from 'models/pathways.model'
import { useCallback } from 'react'
import { useQuery } from 'react-query'
import { isDefined } from 'utils'

export function QueryRangeArticle({
  value,
  onChange,
  disabled = false,
}: {
  value?: number | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (...event: any[]) => void
  disabled?: boolean
}) {
  const learnLibraryQuery = useQuery(['getLibraryArticles'], () => getLibraryArticlesByName(''), {
    cacheTime: pathwayCacheTime,
  })

  const getArticleNameById = useCallback(
    (id: number | null | undefined) => {
      if (!id) return ''

      const articles = learnLibraryQuery?.data?.data?.rows || []
      const articleName = articles.find((article) => article.id === id)?.name
      return articleName || ''
    },
    [learnLibraryQuery],
  )

  const [isEditLearnArticleId, handleEditLearnArticleId, handleCloseEditLearnArticleId] =
    useShowControl()

  const handleDelete = useCallback(() => {
    onChange?.(null)
  }, [onChange])

  if (disabled && !isDefined(value)) {
    return null
  }

  return (
    <>
      <UIEntityItemConnection
        linkId={value || undefined}
        text="Link to Learn Library Article"
        articleName={getArticleNameById(value)}
        onEdit={!disabled ? handleEditLearnArticleId : undefined}
        onDelete={!disabled ? handleDelete : undefined}
      />

      <LinkToLearnLibraryModal
        isOpen={isEditLearnArticleId}
        handleClose={handleCloseEditLearnArticleId}
        value={value || null}
        onSubmit={(newValue) => {
          onChange?.(newValue)
          handleCloseEditLearnArticleId()
        }}
      />
    </>
  )
}
