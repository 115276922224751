import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Box, Button, FormLabel, Stack, TextField } from '@mui/material'
import { deleteQueryRange } from 'api/query'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import React, { useCallback } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { QueryRange } from 'types'
import { QueryRangeArticle } from '../QueryRangeArticle/QueryRangeArticle'
import * as Styled from './QueryRangeItem.styled'

export function QueryRangeItem({
  range,
  index,
  onEdit,
  onReloadQuery,
}: {
  range: QueryRange
  index: number
  onEdit: (range: QueryRange) => void
  onReloadQuery: () => Promise<void>
}) {
  const handleEdit = useCallback(() => {
    onEdit(range)
  }, [onEdit, range])

  const { idToRemove, openDeleteModal, closeDeleteModal, removeLoading, handleConfirmRemove } =
    useRemoveModalControl({
      deleteFunc: deleteQueryRange,
      successCallback: onReloadQuery,
      warning: "Can't remove the range",
    })

  const handleDelete = useCallback(() => {
    openDeleteModal(range.id)
  }, [openDeleteModal, range])

  return (
    <Draggable draggableId={`drag-range-${range.id}`} index={index}>
      {(provided) => (
        <Styled.RangeContainer ref={provided.innerRef} {...provided.draggableProps}>
          <Box {...provided.dragHandleProps} display="flex" mt="2px">
            <DragIndicatorIcon />
          </Box>

          <Stack className="gap-16" flexGrow={1}>
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
              spacing={1}
            >
              <Stack spacing={0.5}>
                <FormLabel>Range Name</FormLabel>
                <TextField size="small" value={range.name} disabled />
              </Stack>

              <Box flexGrow={1} />

              <Button variant="outlined" color="info" onClick={handleEdit}>
                Edit
              </Button>

              <Button variant="outlined" color="error" onClick={handleDelete}>
                Delete
              </Button>
            </Stack>

            <Styled.PointsContainer>
              <Stack spacing={0.5}>
                <FormLabel>Range From</FormLabel>
                <TextField size="small" value={range.pointsFrom} disabled />
              </Stack>

              <Stack spacing={0.5}>
                <FormLabel>Range To</FormLabel>
                <TextField size="small" value={range.pointsTo} disabled />
              </Stack>
            </Styled.PointsContainer>

            <Stack spacing={0.5}>
              <FormLabel>Range Text</FormLabel>
              <TextField
                size="small"
                value={range.text}
                disabled
                minRows={3}
                maxRows={6}
                multiline
              />
            </Stack>

            <QueryRangeArticle value={range.learnArticleId} disabled />
          </Stack>

          <ConfirmRemoveModal
            entityToRemove="Result Range"
            loading={removeLoading}
            isOpen={idToRemove !== null}
            handleConfirm={handleConfirmRemove}
            handleClose={closeDeleteModal}
          />
        </Styled.RangeContainer>
      )}
    </Draggable>
  )
}
