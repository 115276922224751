import { useMemo } from 'react'
import { QueryRangeFormValues } from 'types'
import { object, string } from 'zod'

export function useQueryEditForm() {
  const queryEditFormSchema = useMemo(
    () =>
      object({
        introduction: string()
          .min(1, 'Introduction is required')
          .max(500, 'Please enter a maximum of 500 characters'),
      }),
    [],
  )

  return {
    queryEditFormSchema,
  }
}

export interface RangeValues {
  resultRanges: QueryRangeFormValues[]
}
