import { login, register } from 'api/auth'
import { UserPathwayAlert } from 'features/User'
import { useEffectOnce } from 'hooks/useEffectOnce'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { router } from 'routes/router'
import { routes } from 'routes/routes'
import { useActions, useAppDispatch, useAppSelector } from 'store/hooks'
import { openSidebar } from 'store/slice/navigation.slice'
import { LoginResponse, RegisterRequest, User, UserType } from 'types'
import { clearLocalStorage, getFromLocalStorage, TOKEN, USER_DATA } from 'utils'
import NotificationSys from '../NotificationSystem'
import { timeMeasureSystem } from './TimeMeasureSystem'

interface Props {
  children: React.ReactNode
}

const unknown = 'Unknown'

const defaultValue = {
  isTrainee: false,
  isCareTeam: false,
  isCaregiver: false,
  profileName: unknown,
  authLoading: false,
  regLoading: false,
  login: () => {},
  register: () => {},
  clearUserData: () => {},
  checkIsLoggedIn: () => false,
}

type ContextType = {
  user?: User
  checkIsLoggedIn: () => boolean
  isTrainee: boolean
  isCareTeam: boolean
  isCaregiver: boolean
  profileName: string
  authLoading: boolean
  regLoading: boolean
  login: (
    email: string,
    password: string,
    onSuccess?: (user: LoginResponse) => void,
    onFail?: (reason: Error) => void,
  ) => void
  register: (request: RegisterRequest) => void
  clearUserData: () => void
}

const AuthContext = React.createContext<ContextType>(defaultValue)

export const useAuthContext = () => {
  return useContext(AuthContext)
}

const AuthProvider = (props: Props) => {
  const dispatch = useAppDispatch()
  const [authLoading, setAuthLoading] = useState(false)
  const [regLoading, setRegLoading] = useState(false)

  const { signIn } = useActions()
  const user = useAppSelector((s) => s.currentUser) as User

  const checkIsLoggedIn = useCallback(() => {
    return !!localStorage.getItem(TOKEN)
  }, [])

  const checkPathwayChanges = useCallback(() => {
    if (!checkIsLoggedIn() || !user.isTrainingPathwayTypeChanged) return

    NotificationSys.addNotification({
      level: 'info',
      title: <UserPathwayAlert />,
      autoDismiss: 0,
    })
  }, [user, checkIsLoggedIn])

  const onSuccessRegister = useCallback(() => {
    setTimeout(() => {
      router.navigate(routes.dashboard)
      dispatch(openSidebar())
    }, 0)
  }, [dispatch])

  const onSuccessLogin = useCallback(
    (response: LoginResponse) => {
      const { user } = response
      setTimeout(() => {
        const types = user?.type
        if (!types) {
          return
        }

        if (types.some((it) => it === UserType.ADMIN)) {
          router.navigate(routes.users)
        }

        if (
          types.some(
            (it) =>
              it === UserType.TRAINEE || it === UserType.CAREGIVER || it === UserType.CARETEAM,
          )
        ) {
          router.navigate(routes.journey)
        }
        if (types.some((it) => it === UserType.FAMILY || it === UserType.ORGANIZATION)) {
          router.navigate(routes.journey)
        }

        dispatch(openSidebar())
      }, 0)
    },
    [dispatch],
  )

  const onFailLogin = (reason: unknown) => {
    console.error('Error', reason)
    NotificationSys.showWarning('Incorrect name or password!')
  }

  const onLogin = useCallback(
    (
      email: string,
      password: string,
      onSuccess: (user: LoginResponse) => void = onSuccessLogin,
      onFail: (reason: Error) => void = onFailLogin,
    ) => {
      setAuthLoading(true)

      login(email, password, { suppressErrorHandler: [401] })
        .catch(onFail)
        .then((response) => {
          if (response) {
            signIn({ user: response.data.user, token: response.data.token })
            timeMeasureSystem.startUserSession()
            onSuccess(response.data)
            console.log('--sign in response', response.data)
            console.log('--check saved user after login', getFromLocalStorage(USER_DATA))
          }
        })
        .finally(() => {
          setAuthLoading(false)
        })
    },
    [onSuccessLogin, signIn],
  )

  const onRegister = useCallback(
    (request: RegisterRequest) => {
      setRegLoading(true)

      register(request, { suppressErrorHandler: [401] })
        .then((userData) => {
          if (userData) {
            onLogin(request.email, request.password, onSuccessRegister, () => {
              NotificationSys.showWarning(
                "Can't automatically sign-in after register. Please sign-in manually",
              )
            })
          }
        })
        .finally(() => {
          setRegLoading(false)
        })
    },
    [onLogin, onSuccessRegister],
  )

  const clearUserData = useCallback(() => {
    timeMeasureSystem.stopUserSession()
    clearLocalStorage()
    console.log('--check saved user after logout', getFromLocalStorage(USER_DATA))
  }, [])

  useEffectOnce(() => {
    if (checkIsLoggedIn()) {
      timeMeasureSystem.startUserSession()
    }
  })

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === USER_DATA) {
        window.location.reload()
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  useEffect(() => {
    checkPathwayChanges()
  }, [checkPathwayChanges])

  const value = useMemo(() => {
    return {
      user,
      checkIsLoggedIn,
      isTrainee: !!user?.type?.some((it) => it === UserType.TRAINEE),
      isCareTeam: !!user?.type?.some((it) => it === UserType.CARETEAM),
      isCaregiver: !!user?.type?.some((it) => it === UserType.CAREGIVER),
      profileName: user?.firstname || unknown,
      authLoading,
      regLoading,
      login: onLogin,
      register: onRegister,
      clearUserData,
    }
  }, [user, checkIsLoggedIn, authLoading, regLoading, onLogin, onRegister, clearUserData])

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
}

export default AuthProvider
