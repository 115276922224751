import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const PointsContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background: #e0e0e0;
  padding: 14px;
  border-radius: 4px;
`
