import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { EQuestionType, QuestionAnswer } from 'types'
import { QuestionEditorFieldAnswer } from '../QuestionEditorFieldAnswer/QuestionEditorFieldAnswer'
import { FieldError } from 'react-hook-form'

export function QuestionEditorFieldAnswersList({
  type,
  answers,
  correctId,
  error,
  onChangeCorrectId,
  onChangeAnswers,
  maxLength,
}: {
  type: EQuestionType
  maxLength: number
  answers: QuestionAnswer[]
  correctId: number | null
  error?: FieldError
  onChangeAnswers: (answers: QuestionAnswer[]) => void
  onChangeCorrectId: (correctId: number, correctAnswer: string) => void
}) {
  const addNewOption = () => {
    onChangeAnswers([...answers, { text: '' }])
  }

  const onChangeAnswer = (idx: number, newText: string) => {
    const newAnswers = [...answers.slice(0, idx), { text: newText }, ...answers.slice(idx + 1)]
    onChangeAnswers(newAnswers)
  }

  const onRemoveOption = (idx: number) => {
    const newAnswers = [...answers.slice(0, idx), ...answers.slice(idx + 1)]
    onChangeAnswers(newAnswers)
  }

  return (
    <Box>
      <Stack spacing={2} alignItems="flex-start">
        <Button variant="contained" onClick={addNewOption}>
          Add Option
        </Button>

        <Stack spacing={2} width="100%">
          {answers.map((answer, idx) => (
            <QuestionEditorFieldAnswer
              key={idx}
              canRemove={answers.length > 1}
              checked={idx == correctId}
              maxLength={maxLength}
              radio={type !== EQuestionType.query}
              points={type === EQuestionType.query ? idx + 1 : undefined}
              value={answer.text}
              onChange={(value) => onChangeAnswer(idx, value)}
              onRemove={() => onRemoveOption(idx)}
              onSelect={() => onChangeCorrectId(idx, answer.text)}
            />
          ))}
        </Stack>
      </Stack>
      {error?.message && (
        <Typography color="error" sx={{ fontSize: '0.75rem', marginTop: 0 }}>
          {error.message}
        </Typography>
      )}
    </Box>
  )
}
