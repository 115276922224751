import { AxiosResponse } from 'axios'
import {
  ApiSuccessResponse,
  BaseTableRequest,
  Question,
  QuestionAnswerRequest,
  QuestioningType,
  QuestionRequestCreate,
  QuestionRequestUpdate,
  RequestById,
  Response,
  TableResponse,
} from 'types'
import { prepareTableRequestFilterByName, prepareTableRequestFilterByNameAndPublished } from 'utils'
import axiosInstance from './axios'

export const getQuestions = async (request: BaseTableRequest): Promise<TableResponse<Question>> => {
  return axiosInstance.post('/questions', request)
}

const prepareGetQuestionsRequest = (name: string, type: QuestioningType) => {
  const base = prepareTableRequestFilterByName(name)

  return {
    ...base,
    filter: {
      ...base.filter,
      ['type']: {
        type: 'text',
        op: 'contains',
        value: type === 'query' ? 'query' : 'question',
      },
    },
  }
}

const prepareGetPublishedQuestionsRequest = (
  name: string,
  type: QuestioningType,
  ids: number[],
) => {
  const base = prepareTableRequestFilterByNameAndPublished(name)

  return {
    ...base,
    filter: {
      ...base.filter,
      ['type']: {
        type: 'text',
        op: 'contains',
        value: type === 'query' ? 'query' : 'question',
      },
      ['id']: {
        type: 'number',
        op: 'notIn',
        value: ids,
      },
    },
  }
}

export const getQuestionsByName = async (
  name: string,
  type: QuestioningType,
): Promise<TableResponse<Question>> => {
  const request = prepareGetQuestionsRequest(name, type)

  return axiosInstance.post('/questions', request)
}

export const getQuestionsByNameAndPublished = async (
  name: string,
  type: QuestioningType,
  ids: number[],
): Promise<TableResponse<Question>> => {
  const request = prepareGetPublishedQuestionsRequest(name, type, ids)

  return axiosInstance.post('/questions', request)
}

export const getQuestionsForQuestionnaire = async (
  questionnaireId: string,
  name: string,
  type: QuestioningType,
): Promise<TableResponse<Question>> => {
  const request = prepareGetQuestionsRequest(name, type)

  return axiosInstance.post(`/questions/questionnaire/${questionnaireId}`, request)
}

export const addQuestion = async (request: QuestionRequestCreate): Promise<Response<Question>> => {
  const requestQuestion = {
    ...request,
    answers: request.answers.map((answer) => answer.text),
  }
  return axiosInstance.post('/questions/create', requestQuestion)
}

export const updateQuestion = async (request: Question): Promise<Response<Question>> => {
  const requestQuestion: QuestionRequestUpdate = {
    ...request,
    answers: request.answers.map((answer) => answer.text),
  }
  return axiosInstance.post('/questions/update', requestQuestion)
}

export const getByIdQuestion = async (request: RequestById): Promise<Response<Question>> => {
  return axiosInstance.post('/questions/read', request)
}

export const deleteQuestion = async (request: RequestById): Promise<unknown> => {
  return axiosInstance.post('/questions/delete', request)
}

export const duplicateQuestion = (request: { id: number }): Promise<Response<{ id: number }>> => {
  return axiosInstance.post('/questions/duplicate', request)
}

export const checkQuestionUniqueness = async (request: { name: string }): Promise<boolean> => {
  const result: AxiosResponse<ApiSuccessResponse> = await axiosInstance.post(
    '/questions/check-name',
    request,
  )

  return result.data.success
}

export const checkQuestionAnswer = async (request: QuestionAnswerRequest): Promise<boolean> => {
  const result: AxiosResponse<ApiSuccessResponse> = await axiosInstance.post(
    '/questions/check-answer',
    request,
  )

  return result.data.success
}
