import { OutputData } from '@editorjs/editorjs/types/data-formats/output-data'
import MonitorIcon from '@mui/icons-material/Monitor'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { UIModal } from 'features/UI/components/UIModal/UIModal'
import { MouseEvent, useMemo, useState } from 'react'
import { ArticlePreview } from '../ArticlePreview/ArticlePreview'
import CustomIframe from './CustomIframe'
import { ArticlePreviewContainer, ArticlePreviewTitle } from './Styles'

type ArticlePreviewModalProps = {
  isOpen: boolean
  data: OutputData | null
  lessonName: string
  onClose: () => void
  width?: number
}

enum Devices {
  MONITOR = 'MONITOR',
  TABLET = 'TABLET',
  PHONE = 'PHONE',
}

const DeviceProps = {
  [Devices.MONITOR]: {
    backgroundImage: 'none',
    width: '100%',
    height: '100%',
    internalWidth: '100%',
    internalHeight: '100%',
    iframeWidth: '1024px',
    iframeHeight: 'calc(100vh - 140px)',
    padding: '0',
    internalPadding: '0',
    backgroundSize: 'none',
  },
  [Devices.TABLET]: {
    backgroundImage: 'url("/img/iPad-Air.svg")',
    width: '785px',
    height: '1086px',
    internalWidth: '700px',
    internalHeight: '968px',
    iframeWidth: '750px',
    iframeHeight: '1087px',
    padding: '58px 38px',
    internalPadding: '10px 16px',
    backgroundSize: '1595px',
  },
  [Devices.PHONE]: {
    backgroundImage: 'url("/img/iPhone-14-Pro.svg")',
    width: '397px',
    height: '795px',
    internalWidth: '348px',
    internalHeight: '679px',
    iframeWidth: '380px',
    iframeHeight: '725px',
    padding: '58px 23px',
    internalPadding: '10px 16px',
    backgroundSize: '1002px',
  },
}

export function ArticlePreviewModal({
  isOpen,
  data,
  lessonName,
  onClose,
}: ArticlePreviewModalProps) {
  const [device, setDevice] = useState<keyof typeof Devices>(Devices.MONITOR)

  const styles = useMemo(
    () => ({
      backgroundImage: DeviceProps[device].backgroundImage,
      backgroundSize: DeviceProps[device].backgroundSize,
      width: DeviceProps[device].width,
      height: DeviceProps[device].height,
      padding: DeviceProps[device].padding,
      marginLeft: 'auto',
      marginRight: 'auto',
      overflow: 'hidden',
    }),
    [device],
  )

  const handleDevice = (event: MouseEvent<HTMLElement>, value: keyof typeof Devices) => {
    if (!value) return

    setDevice(value)
  }

  return (
    <UIModal isOpen={isOpen} onClose={onClose} width={1074}>
      <ToggleButtonGroup exclusive value={device} onChange={handleDevice} sx={{ mb: 2 }}>
        <ToggleButton value={Devices.MONITOR}>
          <MonitorIcon />
        </ToggleButton>
        <ToggleButton value={Devices.TABLET}>
          <TabletAndroidIcon />
        </ToggleButton>
        <ToggleButton value={Devices.PHONE}>
          <PhoneAndroidIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      <div style={styles}>
        <CustomIframe
          width={DeviceProps[device].iframeWidth}
          height={DeviceProps[device].iframeHeight}
        >
          <ArticlePreviewContainer
            width={DeviceProps[device].internalWidth}
            height={DeviceProps[device].internalHeight}
            padding={DeviceProps[device].internalPadding}
          >
            <ArticlePreviewTitle>{lessonName}</ArticlePreviewTitle>
            <ArticlePreview data={data} />
          </ArticlePreviewContainer>
        </CustomIframe>
      </div>
    </UIModal>
  )
}
