import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events'
import { PLACEHOLDER } from 'components/column-renderers'
import IdLink from 'components/IdLink'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import { UIModal } from 'features/UI'
import { useShowControl } from 'hooks/useShowControl'
import React, { useMemo } from 'react'
import { routes } from 'routes/routes'
import { LearnArticleResultRange } from 'types'

export function LearnLibraryTopicQueryCell({ items }: { items: LearnArticleResultRange[] }) {
  const [isOpen, onOpen, onClose] = useShowControl()

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        headerName: 'Query Name',
        field: 'query.name',
        cellRenderer: (props: ICellRendererParams<LearnArticleResultRange>) => {
          if (!props.data) return PLACEHOLDER

          if (props.data.query) {
            return (
              <IdLink href={routes.queryEdit} id={props.data.query.id}>
                {props.data.query.name}
              </IdLink>
            )
          }

          return PLACEHOLDER
        },
      },
    ]
  }, [])

  const handleGridReady = (event: GridReadyEvent) => {
    event.api.sizeColumnsToFit()
  }

  const rows = useMemo(() => {
    const result: LearnArticleResultRange[] = []
    items.forEach((item) => {
      if (item.query) {
        const isItemExist = result.find((row) => row.query.id === item.query.id)
        if (!isItemExist) {
          result.push(item)
        }
      }
    })

    return result
  }, [items])

  return (
    <div>
      <a href="#" onClick={onOpen}>
        {rows.length}
      </a>

      <UIModal isOpen={isOpen} onClose={onClose} width={648} title="Connected Queries">
        <div
          className="ag-theme-alpine"
          style={{ height: '400px', width: '600px', fontSize: '1rem' }}
        >
          <InfinityAgGrid
            rowData={rows}
            columnDefs={columnDefs}
            rowModelType="clientSide"
            onGridReady={handleGridReady}
            floatingFiltersHeight={0}
          />
        </div>
      </UIModal>
    </div>
  )
}
