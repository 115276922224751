import { Box, Button, Radio, Stack } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import TextInput from 'components/form-elements/TextInput'
import { useCharactersLeft } from 'hooks/useCharactersLeft'
import * as Styled from './QuestionEditorFieldAnswer.styled'

export function QuestionEditorFieldAnswer({
  radio,
  points,
  checked,
  onSelect,
  value,
  onChange,
  onRemove,
  maxLength,
  canRemove,
}: {
  radio: boolean
  points?: number
  checked: boolean
  onSelect: () => void
  value: string
  onChange: (value: string) => void
  onRemove: () => void
  maxLength: number
  canRemove: boolean
}) {
  const charactersLeft = useCharactersLeft(maxLength, value.length)
  const error =
    value.trim() === ''
      ? 'Field is required'
      : value.length > maxLength
      ? `Please enter a maximum of ${maxLength} characters`
      : null

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="flex-start">
      {radio && <Radio checked={checked} onClick={onSelect} />}
      <Box sx={{ flexGrow: 1 }}>
        <TextInput
          onValueChange={onChange}
          label=""
          value={value}
          fullWidth
          error={!!error}
          helperText={error ?? charactersLeft}
          type="text"
          placeholder={'Start typing'}
        />
      </Box>

      {points !== undefined && (
        <Tooltip title="Question points" placement="top" arrow>
          <Styled.PointsContainer>{points}</Styled.PointsContainer>
        </Tooltip>
      )}

      <Button variant="outlined" sx={{ height: 40 }} onClick={onRemove} disabled={!canRemove}>
        Remove
      </Button>
    </Stack>
  )
}
