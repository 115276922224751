import { Box, Stack } from '@mui/material'
import { getQuery } from 'api/query'
import ScrollContainer from 'components/page/ScrollContainer'
import FailPlaceholder from 'components/placeholders/FailPlaceholder'
import LoadingPlaceholder from 'components/placeholders/LoadingPlaceholder'
import { heightOfToolbar } from 'const'
import { UICollapseProvider } from 'features/UI'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { Query } from 'types'
import QueryGroup from './QueryGroup'

const QueriesTab = () => {
  const [queries, setQueries] = useState<Query[]>([])

  const { isLoading, isError } = useQuery(
    'getQuery',
    () => getQuery({ filter: { isPublished: { type: 'boolean', op: 'equals', value: true } } }),
    {
      onSuccess: ({ data }) => {
        setQueries(data.rows)
      },
    },
  )

  if (isLoading) {
    return (
      <Box sx={{ paddingY: 3 }}>
        <LoadingPlaceholder />
      </Box>
    )
  }

  if (isError) {
    return <FailPlaceholder error="Can't load queries" />
  }

  return (
    <UICollapseProvider>
      <Stack
        paddingTop={1}
        paddingLeft={{ xs: 2, sm: 2 }}
        paddingRight={{ xs: 1, sm: 2 }}
        spacing={1}
      >
        <ScrollContainer deltaSpace={heightOfToolbar}>
          {queries.map((q) => {
            return <QueryGroup key={q.id} id={q.id} title={q.name} />
          })}
        </ScrollContainer>
      </Stack>
    </UICollapseProvider>
  )
}

export default QueriesTab
