import { getQuestionnaireById, updateQuestionnaire } from 'api/questionaries'
import { getQuizById, updateQuiz } from 'api/quiz'
import { Questionnaire, Quiz, RequestById, Response } from 'types'

type IExamParamsByType = {
  [key in 'quiz' | 'questionnaire']: {
    apiMethodLoad: (request: RequestById) => Promise<Response<Quiz | Questionnaire>>
    apiMethodSave: Function
    messageSuccess: string
    messageFail: string
  }
}

export const ExamParamsByType: IExamParamsByType = {
  quiz: {
    apiMethodLoad: getQuizById,
    apiMethodSave: updateQuiz,
    messageSuccess: 'The Quiz was saved in the Lesson',
    messageFail: "Can't load data of Quiz",
  },
  questionnaire: {
    apiMethodLoad: getQuestionnaireById,
    apiMethodSave: updateQuestionnaire,
    messageSuccess: 'The Exam was saved in the Course',
    messageFail: "Can't load data of Exam",
  },
}
