import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseIcon from '@mui/icons-material/Close'
import { CircularProgress, IconButton, Stack, Typography } from '@mui/material'
import { checkQuestionAnswer } from 'api/question'
import { useEffectOnce } from 'hooks/useEffectOnce'
import React, { useCallback, useState } from 'react'
import { LessonAnsweredQuestion } from 'types'
import * as Styled from './styled'
import { ArticleAudioUserView } from 'features/Article/components/ArticleAudioUserView/ArticleAudioUserView'

interface Props {
  answer?: LessonAnsweredQuestion
  title: string
  question?: string
  explanation: string
  correctAnswer?: string
  enQuestionMediaUrl?: string | null
  enExplanationMediaUrl?: string | null
  esQuestionMediaUrl?: string | null
  esExplanationMediaUrl?: string | null
  handleClose: () => void
}

const ExplanationScreen = ({
  answer,
  title,
  explanation,
  question,
  correctAnswer,
  enQuestionMediaUrl,
  enExplanationMediaUrl,
  esQuestionMediaUrl,
  esExplanationMediaUrl,
  handleClose,
}: Props) => {
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null)
  const [isAnswerChecking, setIsAnswerChecking] = useState(false)

  const checkAnswer = useCallback(async () => {
    if (!answer) {
      return
    }

    try {
      setIsAnswerChecking(true)
      const success = await checkQuestionAnswer({
        id: answer.questionId,
        answer: answer.answer,
      })
      setIsCorrect(success)
    } catch (e) {
      console.error(e)
    } finally {
      setIsAnswerChecking(false)
    }
  }, [answer])

  useEffectOnce(() => {
    checkAnswer()
  })

  return (
    <Styled.Content>
      <Styled.CloseIconContainer>
        <IconButton
          size="small"
          onClick={handleClose}
          disableRipple
          sx={{ alignSelf: 'flex-start' }}
        >
          <CloseIcon />
        </IconButton>
      </Styled.CloseIconContainer>

      <div>
        {isAnswerChecking && isCorrect === null && <CircularProgress size={32} />}

        {isCorrect !== null && (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h6" fontWeight={400}>
              {isCorrect ? 'Correct' : 'Incorrect'}
            </Typography>
            {isCorrect ? (
              <CheckCircleRoundedIcon color="success" />
            ) : (
              <CancelRoundedIcon color="error" />
            )}
          </Stack>
        )}
      </div>

      <Typography variant="h6" fontWeight={400}>
        {title}
      </Typography>
      {(enQuestionMediaUrl || esQuestionMediaUrl) && (
        <ArticleAudioUserView
          enMediaUrl={enQuestionMediaUrl as string}
          esMediaUrl={esQuestionMediaUrl as string}
          onSaveMetric={(value, uuid, scope) => undefined}
        />
      )}
      {question && <Typography whiteSpace="pre-line">QUESTION: {question}</Typography>}
      {(enExplanationMediaUrl || esExplanationMediaUrl) && (
        <ArticleAudioUserView
          enMediaUrl={enExplanationMediaUrl as string}
          esMediaUrl={esExplanationMediaUrl as string}
          onSaveMetric={(value, uuid, scope) => undefined}
        />
      )}
      {correctAnswer && (
        <Typography whiteSpace="pre-line">CORRECT ANSWER: {correctAnswer}</Typography>
      )}
      <Typography whiteSpace="pre-line">EXPLANATION: {explanation}</Typography>
    </Styled.Content>
  )
}

export default ExplanationScreen
