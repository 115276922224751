import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Box, Button, Stack } from '@mui/material'
import React, { ReactNode } from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'

export function UIEntityItemBox({
  children,
  isDashed = false,
  additionalElements,
  onEdit,
  onDelete,
  onClick,
  dragHandleProps,
}: {
  children: ReactNode
  isDashed?: boolean
  additionalElements?: ReactNode
  onEdit?: () => void
  onDelete?: () => void
  onClick?: () => void
  dragHandleProps?: DraggableProvidedDragHandleProps | null | undefined
}) {
  const border = isDashed ? '1px dashed #bbb' : '1px solid #bbb'

  return (
    <Stack p={2} sx={{ border, cursor: onClick ? 'pointer' : 'inherit' }} onClick={onClick}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1}>
          {dragHandleProps && (
            <Box {...dragHandleProps} display="flex" mt="2px">
              <DragIndicatorIcon />
            </Box>
          )}
          {children}
        </Stack>
        <Stack direction="row" spacing={2}>
          {onDelete && (
            <Button variant="outlined" color="error" onClick={onDelete}>
              Delete
            </Button>
          )}
          {onEdit && (
            <Button variant="contained" onClick={onEdit}>
              Edit
            </Button>
          )}
        </Stack>
      </Stack>
      {additionalElements}
    </Stack>
  )
}
