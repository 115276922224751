import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { downloadMediaFile } from 'api/mediaFiles'
import { minButtonWidth } from 'const'
import React, {
  BaseSyntheticEvent,
  forwardRef,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import LinearProgressWithLabel from '../LinearProgressWithLabel'
import { AudioLanguages, AudioLanguagesOptions } from 'features/Article/models/Article.model'
import { ArticleAudioUploadItem } from 'features/Article/components/ArticleAudioUploadItem/ArticleAudioUploadItem'
import { QuestionUploadAudioFiles } from 'features/Questions/models/QuestionUploadAudioFile'
import isEqual from 'lodash/isEqual'
import mapValues from 'lodash/mapValues'
import { UIModal } from 'features/UI'
import ConfirmModal from './ConfirmModal'

interface Props {
  isOpen: boolean
  handleClose: () => void

  title: string
  buttonText?: string

  onSubmit: (uploadFiles: QuestionUploadAudioFiles) => void
  onAbortUpload: () => void
  loading?: boolean
  progressBarValue?: number
  initialFiles: QuestionUploadAudioFiles
}

export enum AudioType {
  question = 'question',
  explanation = 'explanation',
}

export const AudioTypeOptions = {
  [AudioType.question]: {
    value: 'question',
    label: 'Question',
  },
  [AudioType.explanation]: {
    value: 'explanation',
    label: 'Explanation',
  },
}

type ModalContentRef = { filesNotChanged: boolean } | undefined

const AudioTypeLabel = ({ type: audioType }: { type: AudioType }) => {
  const label = AudioTypeOptions[audioType].label
  return (
    <Tooltip title={label} placement="top" arrow>
      <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '67px' }}>{label}</Box>
    </Tooltip>
  )
}

const ModalContent = forwardRef<ModalContentRef, Props>(function InnerContent(props, ref) {
  const { handleClose, onSubmit, onAbortUpload, loading, progressBarValue, initialFiles } = props

  const [error, setError] = useState<string | null>(null)
  const [files, setFiles] = useState<QuestionUploadAudioFiles>({ ...initialFiles })
  const [language, setLanguage] = useState('')
  const [audioType, setAudioType] = useState('')

  const filesNotChanged = isEqual(mapValues(initialFiles, 'id'), mapValues(files, 'id'))
  ;(ref as MutableRefObject<ModalContentRef>).current = { filesNotChanged }

  const onChangeFile = (event: React.ChangeEvent) => {
    const file = (event as unknown as BaseSyntheticEvent<File>).target.files[0]
    if (!file) {
      return
    }
    let fileFieldName: keyof QuestionUploadAudioFiles | null = null
    if (language === AudioLanguages.EN && audioType === AudioType.question) {
      fileFieldName = 'enQuestionMediaFile'
    } else if (language === AudioLanguages.EN && audioType === AudioType.explanation) {
      fileFieldName = 'enExplanationMediaFile'
    } else if (language === AudioLanguages.ES && audioType === AudioType.question) {
      fileFieldName = 'esQuestionMediaFile'
    } else if (language === AudioLanguages.ES && audioType === AudioType.explanation) {
      fileFieldName = 'esExplanationMediaFile'
    }
    if (fileFieldName) {
      setFiles((files) => ({
        ...files,
        [fileFieldName as string]: { id: null, file: file, src: URL.createObjectURL(file) },
      }))
    }
    setError(null)
  }

  const onSubmitWrapper = () => {
    onSubmit(files)
  }

  const inputRef = useRef<HTMLInputElement | null>(null)

  const onClickUpload = () => {
    inputRef.current?.click()
  }

  const {
    enQuestionMediaFile,
    enExplanationMediaFile,
    esQuestionMediaFile,
    esExplanationMediaFile,
  } = files
  useEffect(() => {
    const load = async () => {
      if (enQuestionMediaFile?.id && !enQuestionMediaFile?.src) {
        const url = await downloadMediaFile(enQuestionMediaFile.id)
        setFiles((files) => ({
          ...files,
          enQuestionMediaFile: { ...enQuestionMediaFile, src: url },
        }))
      }
      if (enExplanationMediaFile?.id && !enExplanationMediaFile?.src) {
        const url = await downloadMediaFile(enExplanationMediaFile.id)
        setFiles((files) => ({
          ...files,
          enExplanationMediaFile: { ...enExplanationMediaFile, src: url },
        }))
      }
      if (esQuestionMediaFile?.id && !esQuestionMediaFile?.src) {
        const url = await downloadMediaFile(esQuestionMediaFile.id)
        setFiles((files) => ({
          ...files,
          esQuestionMediaFile: { ...esQuestionMediaFile, src: url },
        }))
      }
      if (esExplanationMediaFile?.id && !esExplanationMediaFile?.src) {
        const url = await downloadMediaFile(esExplanationMediaFile.id)
        setFiles((files) => ({
          ...files,
          esExplanationMediaFile: { ...esExplanationMediaFile, src: url },
        }))
      }
    }
    load()
    // eslint-disable-next-line
  }, [])

  const removeHandler = (language: AudioLanguages, type: AudioType) => {
    if (language === AudioLanguages.EN && type === AudioType.question) {
      setFiles({ ...files, enQuestionMediaFile: null })
    } else if (language === AudioLanguages.EN && type === AudioType.explanation) {
      setFiles({ ...files, enExplanationMediaFile: null })
    } else if (language === AudioLanguages.ES && type === AudioType.question) {
      setFiles({ ...files, esQuestionMediaFile: null })
    } else if (language === AudioLanguages.ES && type === AudioType.explanation) {
      setFiles({ ...files, esExplanationMediaFile: null })
    }
  }

  const languageHandler = (e: SelectChangeEvent) => setLanguage(e.target.value)
  const audioTypeHandler = (e: SelectChangeEvent) => setAudioType(e.target.value)

  const uploadButtonIsDisabled = useMemo(() => {
    if (!language || !audioType) {
      return true
    } else if (
      language === AudioLanguages.EN &&
      audioType === AudioType.question &&
      files.enQuestionMediaFile
    ) {
      return true
    } else if (
      language === AudioLanguages.EN &&
      audioType === AudioType.explanation &&
      files.enExplanationMediaFile
    ) {
      return true
    } else if (
      language === AudioLanguages.ES &&
      audioType === AudioType.question &&
      files.esQuestionMediaFile
    ) {
      return true
    } else if (
      language === AudioLanguages.ES &&
      audioType === AudioType.explanation &&
      files.esExplanationMediaFile
    ) {
      return true
    }

    return false
  }, [language, audioType, files])

  return (
    <>
      <Box width="100%">
        <Stack minHeight="72px" spacing={2}>
          <Stack direction="row" spacing={1}>
            <FormControl sx={{ width: '50%' }} size="small">
              <InputLabel>Language</InputLabel>
              <Select value={language} label="Language" onChange={languageHandler}>
                <MenuItem value="">Select a language</MenuItem>
                {Object.keys(AudioLanguagesOptions).map((lang) => {
                  const langOptions = AudioLanguagesOptions[lang as AudioLanguages]
                  return (
                    <MenuItem key={lang} value={lang}>
                      {langOptions.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ width: '50%' }} size="small">
              <InputLabel>Audio type</InputLabel>
              <Select value={audioType} label="Audio type" onChange={audioTypeHandler}>
                <MenuItem value="">Select an audio type</MenuItem>
                {Object.keys(AudioTypeOptions).map((type) => {
                  const audioTypeOptions = AudioTypeOptions[type as AudioType]
                  return (
                    <MenuItem key={type} value={type}>
                      {audioTypeOptions.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>

          <input
            ref={inputRef}
            onChange={onChangeFile}
            id="audio-file"
            type="file"
            accept="audio/wav, audio/mpeg, audio/mp4, audio/ogg, audio/aacp, audio/flac"
            style={{ display: 'none' }}
          />

          <Button variant="contained" onClick={onClickUpload} disabled={uploadButtonIsDisabled}>
            Upload file
          </Button>

          <Stack gap={2}>
            {enQuestionMediaFile && (
              <ArticleAudioUploadItem
                key={`question-en`}
                src={enQuestionMediaFile.src || ''}
                mimeType={'audio/mp3'}
                language={AudioLanguages.EN}
                additionAttr={<AudioTypeLabel type={AudioType.question} />}
                onRemove={(language) => removeHandler(language, AudioType.question)}
              />
            )}

            {enExplanationMediaFile && (
              <ArticleAudioUploadItem
                key={`explanation-en`}
                src={enExplanationMediaFile.src || ''}
                mimeType={'audio/mp3'}
                language={AudioLanguages.EN}
                additionAttr={<AudioTypeLabel type={AudioType.explanation} />}
                onRemove={(language) => removeHandler(language, AudioType.explanation)}
              />
            )}

            {esQuestionMediaFile && (
              <ArticleAudioUploadItem
                key={`question-es`}
                src={esQuestionMediaFile.src || ''}
                mimeType={'audio/mp3'}
                language={AudioLanguages.ES}
                additionAttr={<AudioTypeLabel type={AudioType.question} />}
                onRemove={(language) => removeHandler(language, AudioType.question)}
              />
            )}

            {esExplanationMediaFile && (
              <ArticleAudioUploadItem
                key={`explanation-es`}
                src={esExplanationMediaFile.src || ''}
                mimeType={'audio/mp3'}
                language={AudioLanguages.ES}
                additionAttr={<AudioTypeLabel type={AudioType.explanation} />}
                onRemove={(language) => removeHandler(language, AudioType.explanation)}
              />
            )}
          </Stack>

          {error && (
            <Typography color="error" variant="subtitle2" fontWeight={400} mt={1}>
              {error}
            </Typography>
          )}
          {loading && (
            <Box mt={0.5}>
              <LinearProgressWithLabel value={progressBarValue || 0} />
            </Box>
          )}
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 5 }}>
          {loading ? (
            <Button variant="outlined" onClick={onAbortUpload} sx={minButtonWidth}>
              Stop
            </Button>
          ) : (
            <Button variant="outlined" onClick={handleClose} sx={minButtonWidth}>
              Close
            </Button>
          )}
          <LoadingButton
            loading={loading}
            variant="contained"
            sx={minButtonWidth}
            onClick={onSubmitWrapper}
            disabled={filesNotChanged}
          >
            Confirm
          </LoadingButton>
        </Stack>
      </Box>
    </>
  )
})

const UploadAudioModal = (props: Props) => {
  const contentRef = useRef<ModalContentRef>()
  const [saveConfirmOpen, setSaveConfirmOpen] = useState(false)
  const { isOpen, title, handleClose } = props

  const handleCloseConfirm = () => {
    setSaveConfirmOpen(false)
  }

  const handleConfirm = () => {
    setSaveConfirmOpen(false)
    handleClose()
  }

  const handleCloseWrapper = () => {
    if (contentRef.current?.filesNotChanged) {
      handleClose()
      return
    }
    setSaveConfirmOpen(true)
  }

  return (
    <>
      <UIModal title={title} isOpen={isOpen} onClose={handleCloseWrapper} width={580}>
        {isOpen ? (
          <ModalContent {...props} handleClose={handleCloseWrapper} ref={contentRef} />
        ) : (
          <Box />
        )}
      </UIModal>
      {saveConfirmOpen && (
        <ConfirmModal
          title=""
          loading={false}
          body="Unsaved audio files will be lost. Would you like to close the window?"
          handleClose={handleCloseConfirm}
          handleConfirm={handleConfirm}
        />
      )}
    </>
  )
}

export default React.memo(UploadAudioModal)
