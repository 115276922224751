import PauseCircleIcon from '@mui/icons-material/PauseCircle'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import { Stack, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { red } from '@mui/material/colors'
import React, { ChangeEvent, SyntheticEvent, useCallback, useRef, useState } from 'react'
import { useMetric } from 'services/Metric'
import { IMetricScope, IMetricType } from 'types'
import * as Styled from './UIAudioPlayer.styled'

const iconSX = { fontSize: '2.25rem', color: red[600], cursor: 'pointer' }

const formatTime = (num: number) => {
  const fixed = Math.trunc(num)
  const sec = String(fixed % 60).padStart(2, '0')
  const minutes = String(Math.trunc(fixed / 60)).padStart(2, '0')
  return `${minutes}:${sec}`
}

export function UIAudioPlayer({
  src,
  mediaFileMimeType,
  metricEntityId,
  metricType,
  onSaveMetric,
}: {
  src: string
  mediaFileMimeType?: string
  metricEntityId?: number
  metricType?: IMetricType
  onSaveMetric?: (value: number, uuid: string, scope: IMetricScope) => void
}) {
  const audioRef = useRef<HTMLAudioElement | null>(null)

  const [isPlaying, setIsPlaying] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [currentProgress, setCurrentProgress] = useState(0)
  const [duration, setDuration] = useState(0)

  const { onMetricTimerStart, onMetricSave } = useMetric({
    type: metricType,
    entityId: metricEntityId,
    scope: IMetricScope.audio,
    isImmediateStart: false,
    hasIdleTimer: false,
    onSave: (value: number, uuid: string, scope: IMetricScope, isDestroyed: boolean) => {
      if (!isDestroyed || (isDestroyed && isPlaying)) {
        onSaveMetric?.(value, uuid, scope)
      }
    },
  })

  const handlePlay = useCallback(() => {
    setIsPlaying(true)
    onMetricTimerStart()
  }, [onMetricTimerStart])

  const handlePlayControl = useCallback(() => {
    audioRef.current?.play()
  }, [])

  const handlePause = useCallback(() => {
    setIsPlaying(false)
    onMetricSave()
  }, [onMetricSave])

  const handlePauseControl = useCallback(() => {
    audioRef.current?.pause()
  }, [])

  const handleReady = useCallback(() => {
    setIsReady(true)
  }, [])

  const handleDurationChange = useCallback((event: SyntheticEvent<HTMLAudioElement>) => {
    setDuration(event.currentTarget.duration)
  }, [])

  const handleTimeUpdate = useCallback((event: SyntheticEvent<HTMLAudioElement>) => {
    setCurrentProgress(event.currentTarget.currentTime)
  }, [])

  const handleClickTime = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (audioRef.current) {
      audioRef.current.currentTime = event.currentTarget.valueAsNumber
    }
    setCurrentProgress(event.currentTarget.valueAsNumber)
  }, [])

  const currentPercentage = duration ? `${(currentProgress / duration) * 100}%` : '0%'
  const trackStyling = `
    linear-gradient(to right, #333 ${currentPercentage}, #777 ${currentPercentage})
  `

  return (
    <Stack flexDirection="row" alignItems="center">
      <audio
        ref={audioRef}
        preload="metadata"
        onPlaying={handlePlay}
        onPause={handlePause}
        onLoadedMetadata={handleReady}
        onTimeUpdate={handleTimeUpdate}
        onDurationChange={handleDurationChange}
      >
        <source src={src} type={mediaFileMimeType} />
      </audio>

      {!isReady ? (
        <Styled.LoaderContainer>
          <CircularProgress style={{ width: 24, height: 24 }} />
        </Styled.LoaderContainer>
      ) : (
        <>
          {isPlaying ? (
            <PauseCircleIcon onClick={handlePauseControl} sx={iconSX} />
          ) : (
            <PlayCircleIcon onClick={handlePlayControl} sx={iconSX} />
          )}
        </>
      )}

      <Styled.Range
        type="range"
        value={currentProgress}
        step="0.001"
        min="0"
        max={duration ? duration : `${duration}`}
        onChange={handleClickTime}
        style={{ background: trackStyling }}
      />
      <Typography ml={1} mb={-0.25} color="textSecondary">
        {formatTime(currentProgress)} / {formatTime(duration || 0)}
      </Typography>
    </Stack>
  )
}
