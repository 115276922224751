import { zodResolver } from '@hookform/resolvers/zod'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, FormHelperText, Stack, Typography } from '@mui/material'
import { createQueryRange, updateQueryRange } from 'api/query'
import FormInput from 'components/form-elements/FormInput'
import NotificationSys from 'components/NotificationSystem'
import { FIELD_IS_REQUIRED, minButtonWidth } from 'const'
import { UIModal } from 'features/UI'
import { useShowControl } from 'hooks/useShowControl'
import { uniq } from 'lodash'
import flatten from 'lodash/flatten'
import isEqual from 'lodash/isEqual'
import React, { useCallback, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { QueryRange } from 'types'
import { coerce, number, object, string, TypeOf } from 'zod'
import { fillRange } from '../../models/QueryRange.model'
import { QueryRangeArticle } from '../QueryRangeArticle/QueryRangeArticle'
import * as Styled from './QueryRangeModalForm.styled'

function QueryRangeModalFormContent({
  queryId,
  maxPoints,
  range,
  ranges,
  onReloadQuery,
}: {
  queryId: number
  maxPoints: number
  range: QueryRange | null
  ranges: QueryRange[]
  onReloadQuery: () => Promise<void>
}) {
  const formSchema = object({
    id: number().optional(),
    name: string().optional(),
    text: string().min(1, FIELD_IS_REQUIRED),
    pointsFrom: coerce.number().int().min(1),
    pointsTo: coerce.number().int().min(1).max(maxPoints),
    learnArticleId: number().nullable().optional(),
  })
    .refine((data) => data.pointsFrom < data.pointsTo, {
      message: "'Range To' must be greater than 'Range From'",
      path: ['pointsTo'],
    })
    .refine(
      (data) => {
        const points = ranges
          .filter((rangeItem) => {
            if (data.id === undefined) return true
            return rangeItem.id !== data.id
          })
          .map((rangeItem) => fillRange(rangeItem.pointsFrom, rangeItem.pointsTo))
        const filledCurrentRange = fillRange(data.pointsFrom, data.pointsTo)

        const flattenArray = flatten(filledCurrentRange.concat(...points))
        const uniqueValues = uniq(flattenArray)
        const hasDuplicates = flattenArray.length !== uniqueValues.length
        return !hasDuplicates
      },
      {
        message: 'There are overlapping values',
        path: ['pointsTo'],
      },
    )
    .refine(
      (data) => {
        return !(data.pointsTo !== maxPoints && data.learnArticleId === null)
      },
      {
        message: 'A link to a Learn Library Article is required',
        path: ['learnArticleId'],
      },
    )

  type FormType = TypeOf<typeof formSchema>

  const emptyRange = {
    name: '',
    text: '',
    pointsFrom: 0,
    pointsTo: 0,
    learnArticleId: null,
  }

  const formSettings = {
    mode: 'onChange' as const,
    resolver: zodResolver(formSchema),
    defaultValues: range || emptyRange,
  }

  const methods = useForm<FormType>(formSettings)

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    setValue,
  } = methods

  const [isOpenConfirmArticle, onOpenConfirmArticle, onCloseConfirmArticle] = useShowControl()
  const [learnArticleId, setLearnArticleId] = useState<number | null>(null)

  const handleChangeArticle = useCallback(() => {
    setValue('learnArticleId', learnArticleId)
    setLearnArticleId(null)
    onCloseConfirmArticle()
  }, [learnArticleId, onCloseConfirmArticle, setValue])

  const handleCheckChangeArticle = useCallback(
    (value: number) => {
      const hasSameArticle = ranges.find((rangeItem) => rangeItem.learnArticleId === value)
      if (hasSameArticle) {
        setLearnArticleId(value)
        onOpenConfirmArticle()
      } else {
        setValue('learnArticleId', value)
      }
    },
    [onOpenConfirmArticle, ranges, setValue],
  )

  const handleSave = useCallback(
    async (values: FormType) => {
      try {
        if (range) {
          const { learnArticleName, order, ...rangeToCompare } = range
          if (!isEqual(values, rangeToCompare)) {
            await updateQueryRange({
              ...values,
              id: range.id,
              queryId,
            })
          }
        } else {
          await createQueryRange({
            ...values,
            queryId,
          })
        }

        await onReloadQuery()
      } catch (error) {
        NotificationSys.showWarning('An error occurred while saving the range')
      }
    },
    [onReloadQuery, queryId, range],
  )

  return (
    <FormProvider {...methods}>
      <Stack component="form" className="gap-16" flexGrow={1} onSubmit={handleSubmit(handleSave)}>
        <FormInput
          name="name"
          label="Range Name"
          placeholder="Enter Range Name"
          checkTouchField={false}
        />

        <Styled.PointsContainer>
          <FormInput
            type="number"
            name="pointsFrom"
            label="Range From"
            placeholder="Enter points"
            checkTouchField={false}
          />

          <FormInput
            type="number"
            name="pointsTo"
            label="Range To"
            placeholder="Enter points"
            checkTouchField={false}
          />
        </Styled.PointsContainer>

        <FormInput
          name="text"
          label="Range Text"
          placeholder="Start typing"
          fullWidth
          minRows={3}
          maxRows={6}
          multiline
          checkTouchField={false}
        />

        <Controller
          control={control}
          name="learnArticleId"
          render={({ field, formState: { errors } }) => {
            return (
              <div>
                <QueryRangeArticle value={field.value} onChange={handleCheckChangeArticle} />
                {errors.learnArticleId && (
                  <FormHelperText error>{errors.learnArticleId.message}</FormHelperText>
                )}
              </div>
            )
          }}
        />

        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Save
          </LoadingButton>
        </Box>
      </Stack>

      <UIModal
        isOpen={isOpenConfirmArticle}
        title="Confirm Change Learn Library article"
        onClose={onCloseConfirmArticle}
      >
        <Stack>
          <Typography>
            Please note, this Learn Library article has already been added to a different result. If
            the Article should remain the same, please select &quot;Confirm&quot;.
          </Typography>

          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 5 }}>
            <Button variant="outlined" onClick={onCloseConfirmArticle} sx={minButtonWidth}>
              Close
            </Button>
            <Button
              variant="contained"
              type="button"
              sx={minButtonWidth}
              onClick={handleChangeArticle}
            >
              Confirm
            </Button>
          </Stack>
        </Stack>
      </UIModal>
    </FormProvider>
  )
}

export function QueryRangeModalForm({
  isOpen,
  maxPoints,
  queryId,
  range,
  ranges,
  onClose,
  onReloadQuery,
}: {
  isOpen: boolean
  maxPoints: number
  queryId: number
  range: QueryRange | null
  ranges: QueryRange[]
  onClose: () => void
  onReloadQuery: () => Promise<void>
}) {
  return (
    <UIModal
      isOpen={isOpen}
      title={!!range ? 'Edit Result Range' : 'Add Result Range'}
      onClose={onClose}
      width={800}
    >
      {isOpen ? (
        <QueryRangeModalFormContent
          queryId={queryId}
          range={range}
          ranges={ranges}
          maxPoints={maxPoints}
          onReloadQuery={onReloadQuery}
        />
      ) : (
        <Box />
      )}
    </UIModal>
  )
}
