import { Box } from '@mui/material'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { deleteQuery, getQuery, getQueryById, publishQuery } from 'api/query'
import columnRenderers from 'components/column-renderers'
import ConfirmPublishModal from 'components/modals/ConfirmPublishModal'
import ConfirmRemoveModal from 'components/modals/ConfirmRemoveModal'
import NotificationSys from 'components/NotificationSystem'
import InfinityAgGrid from 'components/table/InfinityAgGrid'
import TableToolbar from 'components/table/TableToolbar'
import { Metadata } from 'features/Metadata'
import { columnCreatedBy, columnUpdatedBy, getActionColumn, RowAction } from 'features/UI'
import { useGridControl } from 'hooks/useGridControl'
import { PublishAction, usePublishModalControl } from 'hooks/usePublishModalControl'
import { useRemoveModalControl } from 'hooks/useRemoveModalControl'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { BaseTableRequest, FilterModel, Query, TableResponse } from 'types'
import { useGetDataSource, withIdParam } from 'utils'
import { useQueryMetadata } from '../../hooks/useQueryMetadata'
import { QueryControlAdd } from '../QueryControlAdd/QueryControlAdd'

export function QueryTablePage() {
  const navigate = useNavigate()
  const [queryList, setQueryList] = useState<TableResponse<Query>>()

  const getRequest = useCallback(async (req: BaseTableRequest) => {
    const filter: FilterModel = {
      ...(req.filter || {}),
    }

    const request = { ...req, filter }

    const res = await getQuery(request)
    setQueryList(res)
    return res
  }, [])

  const getDataSource = useGetDataSource(getRequest)
  const { onGridReady, gridApi } = useGridControl(getDataSource)

  const { idToRemove, openDeleteModal, closeDeleteModal, removeLoading, handleConfirmRemove } =
    useRemoveModalControl({
      deleteFunc: deleteQuery,
      warning: "Can't delete query",
      successCallback: () => {
        gridApi?.purgeInfiniteCache()
      },
    })

  const handleDelete = useCallback(
    (tagId: number) => {
      openDeleteModal(tagId)
    },
    [openDeleteModal],
  )

  const publishActionRender = useCallback(
    (props: ICellRendererParams<{ isPublished: boolean }>) => {
      const isPublished = props?.data?.isPublished
      return isPublished ? 'Unpublish' : 'Publish'
    },
    [],
  )

  const {
    action,
    idToPublish,
    openPublishModal,
    closePublishModal,
    publishLoading,
    handleConfirmPublish,
  } = usePublishModalControl<Query>({
    successCallback: (_: number, action: PublishAction) => {
      const isPublished = action === PublishAction.Publish
      if (isPublished) {
        NotificationSys.showSuccess(`Query is published`)
      } else {
        NotificationSys.showSuccess(`Query is unpublished`)
      }
      gridApi?.purgeInfiniteCache()
    },
    loadFunc: getQueryById,
    updateFunc: (query: Query) => {
      return publishQuery({
        id: query.id,
        isPublished: query.isPublished,
      })
    },
  })

  const onPublish = useCallback(
    (id: number, props: ICellRendererParams<{ isPublished: boolean }>) => {
      const isPublished = props?.data?.isPublished
      const action = isPublished ? PublishAction.Unpublish : PublishAction.Publish
      openPublishModal(id, action)
    },
    [openPublishModal],
  )

  const handleClickEdit = useCallback(
    (id: number) => {
      navigate(withIdParam(routes.queryEdit, id))
    },
    [navigate],
  )

  const actions: RowAction[] = useMemo(() => {
    return [
      {
        name: 'Edit',
        onClick: handleClickEdit,
      },
      {
        renderName: publishActionRender,
        onClick: onPublish,
      },
      {
        name: 'Delete',
        onClick: handleDelete,
      },
    ]
  }, [handleClickEdit, handleDelete, onPublish, publishActionRender])

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        headerName: 'Query Name',
        field: 'name',
        flex: 1,
        filter: 'agTextColumnFilter',
        cellRenderer: columnRenderers.createLink(
          {
            idSelector: (item) => item?.id,
            nameSelector: (item) => item?.name,
          },
          routes.queryEdit,
        ),
      },
      {
        headerName: 'Question Count',
        field: 'questionsCount',
      },
      {
        headerName: 'Linked Learn library Article',
        field: 'learnArticleName',
      },
      {
        headerName: 'Published',
        cellRenderer: columnRenderers.published,
        colId: 'isPublished',
      },
      {
        headerName: 'Creation Date',
        cellRenderer: columnRenderers.createdAt,
        minWidth: 220,
        colId: 'createdAt',
      },
      columnCreatedBy(),
      {
        headerName: 'Last Edited',
        cellRenderer: columnRenderers.updatedAt,
        minWidth: 220,
        colId: 'updatedAt',
        sort: 'desc',
      },
      columnUpdatedBy(),
      { ...getActionColumn(actions) },
    ]
  }, [actions])

  const metadata = useQueryMetadata(queryList)

  return (
    <>
      <TableToolbar title="Queries Overview">
        <QueryControlAdd />
      </TableToolbar>

      <Box mb={2}>
        <Metadata data={metadata} />
      </Box>

      <InfinityAgGrid pagination columnDefs={columnDefs} onGridReady={onGridReady} />

      <ConfirmRemoveModal
        entityToRemove="Query"
        loading={removeLoading}
        isOpen={idToRemove !== null}
        handleConfirm={handleConfirmRemove}
        handleClose={closeDeleteModal}
      />

      <ConfirmPublishModal
        loading={publishLoading}
        handleClose={closePublishModal}
        isOpen={idToPublish !== null}
        handleConfirm={handleConfirmPublish}
        action={action}
        entity="Question"
      />
    </>
  )
}
