import { MediaFile } from './mediaFiles.types'

export enum EQuestionType {
  question = 'question',
  query = 'query',
}

export interface QuestionAnswer {
  text: string
  points?: number
}

export type Question = {
  id: number
  name: string
  stem: string
  instructions: string
  type: EQuestionType
  answers: QuestionAnswer[]
  correctAnswer: string
  question: string
  explanation: string
  mediaFileId?: number | null
  mediaFile?: MediaFile | null
  enQuestionMediaFileId?: number | null
  enQuestionMediaUrl?: string | null
  enExplanationMediaFileId?: number | null
  enExplanationMediaUrl?: string | null
  esQuestionMediaFileId?: number | null
  esQuestionMediaUrl?: string | null
  esExplanationMediaFileId?: number | null
  esExplanationMediaUrl?: string | null
}

export type QuestionRequestCreate = Omit<Question, 'id'>

export type QuestionRequestUpdate = Omit<Question, 'answers'> & {
  answers: string[]
}

export interface QuestionAnswerRequest {
  id: number
  answer: string
}
