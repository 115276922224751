import FormInput from 'components/form-elements/FormInput'
import { useCharactersLeft } from 'hooks/useCharactersLeft'
import { ChangeEvent, useCallback, useState } from 'react'

export const QuestionEditorFieldInstruction = ({
  value,
  maxLength,
}: {
  value: string
  maxLength: number
}) => {
  const [instruction, setInstruction] = useState(value)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setInstruction(e.target.value)
    },
    [setInstruction],
  )

  const charactersLeft = useCharactersLeft(maxLength, instruction.length)

  return (
    <FormInput
      name="instructions"
      label="Instruction"
      placeholder="Start typing"
      fullWidth
      minRows={3}
      maxRows={6}
      multiline
      onChange={handleChange}
      helperText={charactersLeft}
      checkTouchField={false}
    />
  )
}
