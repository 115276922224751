import { Visibility, VisibilityOff } from '@mui/icons-material'
import { FormLabel, IconButton, InputAdornment, Stack, TextField } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { useToggle } from 'hooks/useToggle'
import _get from 'lodash/get'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type IFormInputProps = {
  name: string
  checkTouchField?: boolean
} & TextFieldProps

const FormInput = ({
  label,
  sx = {},
  name,
  defaultValue,
  required,
  type,
  checkTouchField = true,
  ...otherProps
}: IFormInputProps) => {
  const [showPassword, togglePassword] = useToggle(false)

  const additionalProps = useMemo(() => {
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
    }
    if (type === 'password') {
      return {
        type: showPassword ? 'text' : 'password',
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        },
      }
    }
    return {}
  }, [showPassword, togglePassword, type])

  const {
    control,
    formState: { errors, touchedFields },
  } = useFormContext()

  const isPath = name.includes('.')
  const error = isPath ? _get(errors, name) : errors[name]
  const touchedField = isPath ? _get(touchedFields, name) : touchedFields[name]

  const hasError = !!error && (checkTouchField ? !!touchedField : true)

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Stack spacing={0.5} sx={sx}>
          {label && <FormLabel required={required}>{label}</FormLabel>}
          <TextField
            required={required}
            size="small"
            {...otherProps}
            {...field}
            {...additionalProps}
            onChange={(e) => {
              field.onChange(e)
              otherProps.onChange && otherProps.onChange(e)
            }}
            error={hasError}
            helperText={(hasError ? error?.message : otherProps.helperText) as string}
          />
        </Stack>
      )}
    />
  )
}

export default FormInput
